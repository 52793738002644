import React from 'react';

// commenting for netlify
// import { Typography, CssBaseline, Container } from '@mui/material';

import { Typography, Container } from '@mui/material';

const Settings = () => {
    // const classes = useStyles();

    return(
        <Container maxWidth="sm" style={{marginTop: '10px'}}>
            <div> 
                <Typography variant='h4'>Hello This is Settings..</Typography>  
              
            </div>
        </Container>
        
    );
}

export default Settings;